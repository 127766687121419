<template>
  <div id="app">
    <router-view />
    <CompanyUser /> <!-- 始终显示的聊天组件 -->
  </div>
</template>

<script>
import CompanyUser from "@/views/CompanyUser.vue";
export default {
  name: "App",
  components: {
    CompanyUser,
  },
  computed: {
  }
};
</script>

<style>
nav {
  background-color: #007bff;
  padding: 15px;
}

nav a {
  color: white;
  margin: 0 15px;
  text-decoration: none;
}

nav a:hover {
  text-decoration: underline;
}

#app {
  font-family: Avenir, Helvetika, Arial, sans-serif;
  text-align: center;
  color: #2c3e50;
}
</style>
