<template>
  <div class="apple-style">
    <el-container>
      <!-- 简洁的header -->
      <el-header class="apple-header">
        <h1>{{ this.prodHeader }}</h1>
      </el-header>

      <el-main>
        <!-- 主打产品展示 -->
        <section class="hero-section">
          <h1 class="hero-title">{{ prodTitle }}</h1>
          <div class="price-container">
            <div class="price-tag" v-if="this.prodPrice">
              <span class="currency">$</span>
              <span class="amount">{{ this.prodPrice }}</span>
            </div>
            <div class="price-details" v-if="this.prodOriginalPrice">
              <span class="original-price">List Price: ${{ this.prodOriginalPrice }}</span>
              <span class="discount-tag" v-if="this.prodDiscount">{{ this.prodDiscount }}% OFF</span>
            </div>
          </div>
          <div class="cta-buttons">
            <el-button class="learn-more-btn" @click="learnMore">Learn more</el-button>
          </div>
        </section>

        <!-- 视频展示区 -->
        <section class="video-section" v-if="prodVideourl">
          <div class="video-wrapper">
            <iframe
              :src="prodVideourl"
              frameborder="0"
              allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
              loading="lazy"
              scrolling="no"
            ></iframe>
          </div>
        </section>

        <!-- 大图产品展示 - 只展示图片 -->
        <section class="product-showcase">
          <div class="image-grid">
            <div v-for="(item, index) in prodImgurl"
                 :key="index"
                 class="image-container"
                 @click="handleImageClick(index)"
            >
              <img :src="item.src" :alt="item.alt" class="product-image" />
            </div>
            <!-- 添加末尾占位元素 -->
            <div class="image-container-spacer"></div>
          </div>
          <div class="gallery-controls">
            <el-button circle class="gallery-nav-btn" @click="scrollGallery('left')">
              <i class="el-icon-arrow-left"></i>
            </el-button>
            <el-button circle class="gallery-nav-btn" @click="scrollGallery('right')">
              <i class="el-icon-arrow-right"></i>
            </el-button>
          </div>
        </section>

        <!-- 新增：产品详情独立板块 -->
        <section class="product-details">
          <div class="content-container">
            <h2>About this item</h2>
            <div class="content-wrapper" v-html="formatContent(prodContent)"></div>
          </div>
        </section>

        <!-- 联系我们 -->
        <section class="contact-section">
          <h2>Contact Us</h2>
          <p>Thank you for your support and we sincerely invite you to be our product experience officer. Product Experience Officers can obtain our products for free. After using them, please give us feedback on your actual usage experience.</p>
          <el-button class="contact-btn" @click="sendEmail">{{ prodEmail }}</el-button>
        </section>

        <!-- 添加图片预览对话框 -->
        <el-dialog
          :visible.sync="previewVisible"
          :append-to-body="true"
          custom-class="preview-dialog"
          :show-close="false"
          @click.native="closePreview"
        >
          <img :src="currentPreviewImage" class="preview-image" />
          <div class="preview-close" @click="closePreview">
            <i class="el-icon-close"></i>
          </div>
          <div class="preview-controls">
            <el-button circle class="preview-nav-btn" @click.stop="prevImage" v-show="previewIndex > 0">
              <i class="el-icon-arrow-left"></i>
            </el-button>
            <el-button circle class="preview-nav-btn" @click.stop="nextImage" v-show="previewIndex < prodImgurl.length - 1">
              <i class="el-icon-arrow-right"></i>
            </el-button>
          </div>
        </el-dialog>
      </el-main>
    </el-container>
  </div>
</template>

<script>
export default {
  name: "CompanyHome",
  data() {
    return {
      prodHeader: '',
      prodEmail: '',
      prodVideourl: '',
      prodAmazonurl: '',
      prodImgurl: [],
      prodTitle: '',
      prodContent: '',
      prodPrice: '',
      prodOriginalPrice: '',
      prodDiscount: '',
      prodShipping: '',
      previewVisible: false,
      previewIndex: 0,
    };
  },
  computed: {
    currentPreviewImage() {
      return this.prodImgurl[this.previewIndex]?.src || '';
    }
  },
  mounted() {
    this.init();
    this.select();
    this.initScrollObserver();
    this.initParallaxEffect();

    // 为段落添加延迟动画
    document.querySelectorAll('.content-paragraph').forEach((p, index) => {
      p.style.setProperty('--paragraph-index', index);
    });
  },
  methods: {
    init() {
      const params = {
        visitDomain: window.location.hostname,
      }
      this.$axios.post('/visit/insert', params).then(response => {
        if (response.data.code === 0) {
          console.log();
        }
      }).catch(error => {
        console.error(error);
      });
    },
    select() {
      const params = {
        prodName: window.location.hostname.split('.')[1],
      }
      this.$axios.post('/prod/select', params).then(response => {
        if (response.data.code === 0) {
          const data = response.data.data;
          this.prodHeader = data.prodHeader;
          this.prodEmail = data.prodEmail;
          this.prodVideourl = data.prodVideourl;
          this.prodAmazonurl = data.prodAmazonurl;
          const imgurl = data.prodImgurl.split(",");

          // 设置第一张图片为hero背景
          if (imgurl.length > 0) {
            const heroSection = document.querySelector('.hero-section');
            if (heroSection) {
              heroSection.style.setProperty('--hero-bg-image', `url(${imgurl[0]})`);
            }
          }

          // 其余图片用于轮播
          for (let i = 0; i < imgurl.length; i++) {
            const prodImgurlmap = {};
            prodImgurlmap.src = imgurl[i];
            prodImgurlmap.alt = '描述' + (i + 1);
            this.prodImgurl.push(prodImgurlmap);
          }

          this.prodTitle = data.prodTitle;
          this.prodContent = data.prodContent;
          this.prodPrice = data.prodPrice;
          this.prodOriginalPrice = data.prodOriginalPrice;
          this.prodDiscount = data.prodDiscount;
          this.prodShipping = data.prodShipping;
          document.title = data.prodName;
        }
      }).catch(error => {
        console.error(error);
      });
    },
    sendEmail() {
      const subject = encodeURIComponent("邮件主题"); // 邮件主题
      const body = encodeURIComponent("这是邮件正文。"); // 邮件正文
      const recipient = this.prodEmail; // 收件人邮箱

      // 创建mailto链接
      const mailtoLink = `mailto:${recipient}?subject=${subject}&body=${body}`;

      // 打开邮件客户端
      window.location.href = mailtoLink;
    },
    learnMore() {
      window.open(this.prodAmazonurl, "_blank"); // 在新窗口中打开
    },
    initScrollObserver() {
      const options = {
        threshold: 0.2,
        rootMargin: '0px 0px -100px 0px'
      };

      const observer = new IntersectionObserver((entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            entry.target.classList.add('visible');
            observer.unobserve(entry.target);
          }
        });
      }, options);

      // 观察所有需要动画的元素
      document.querySelectorAll('.scroll-trigger, .video-section, .product-showcase').forEach(el => {
        observer.observe(el);
      });
    },
    initParallaxEffect() {
      const hero = document.querySelector('.hero-section');
      if (!hero) return;

      window.addEventListener('mousemove', (e) => {
        const { clientX, clientY } = e;
        const { innerWidth, innerHeight } = window;

        const moveX = (clientX - innerWidth / 2) * 0.01;
        const moveY = (clientY - innerHeight / 2) * 0.01;

        hero.style.transform = `translate3d(${moveX}px, ${moveY}px, 0)`;
      });
    },
    formatContent(content) {
      // 将内容按段落分割
      const paragraphs = content.split('\n');
      return paragraphs
        .map(p => p.trim())
        .filter(p => p.length > 0)
        .map(p => `<p class="content-paragraph">${p}</p>`)
        .join('');
    },
    handleImageClick(index) {
      this.previewIndex = index;
      this.previewVisible = true;
    },
    closePreview() {
      this.previewVisible = false;
    },
    prevImage() {
      if (this.previewIndex > 0) {
        this.previewIndex--;
      }
    },
    nextImage() {
      if (this.previewIndex < this.prodImgurl.length - 1) {
        this.previewIndex++;
      }
    },
    scrollGallery(direction) {
      const gallery = document.querySelector('.image-grid');
      const imageContainer = gallery.querySelector('.image-container');
      const scrollAmount = imageContainer ? imageContainer.offsetWidth + 40 : 800;

      if (gallery) {
        const scrollPosition = direction === 'left'
          ? gallery.scrollLeft - scrollAmount
          : gallery.scrollLeft + scrollAmount;

        gallery.scrollTo({
          left: scrollPosition,
          behavior: 'smooth',
          block: 'nearest',
          inline: 'start'
        });
      }
    }
  }
};
</script>

<style scoped>
.apple-style {
  background-color: #000;
  color: #fff;
  min-height: 100vh;
  font-family: -apple-system, BlinkMacSystemFont, "SF Pro Text", "Helvetica Neue", Arial, sans-serif;
  animation: pageLoad 1s ease-out;
  overflow-x: hidden; /* 防止水平滚动 */
}

@keyframes pageLoad {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.apple-header {
  background: rgba(0, 0, 0, 0.85);
  backdrop-filter: saturate(180%) blur(20px);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  transition: background-color 0.3s ease;
  width: 100%;
  z-index: 100;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.apple-header:hover {
  background: rgba(0, 0, 0, 0.95);
}

.apple-header h1 {
  font-size: 21px;
  font-weight: 400;
  margin: 0;
}

.hero-section {
  height: 93vh;
  margin-bottom: 100px;  /* 增加与下一个区块的间距 */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: linear-gradient(180deg, #000 0%, #111 100%);
  position: relative;
  overflow: hidden;
  --hero-bg-image: none;
  transition: transform 0.5s ease-out;
  transform-style: preserve-3d;
  perspective: 1000px;
}

/* 修改背景图片容器样式 */
.hero-section::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-size: contain;  /* 改为 contain 以防止图片失真 */
  background-position: center;
  background-repeat: no-repeat;
  opacity: 0.5;  /* 稍微调低透明度 */
  z-index: 0;
  transition: opacity 0.3s ease;
  background-image: var(--hero-bg-image);
  animation: heroImageFade 2s ease-out forwards;
}

@keyframes heroImageFade {
  0% {
    opacity: 0;
    transform: scale(1.1);
  }
  100% {
    opacity: 0.5;
    transform: scale(1);
  }
}

/* 移除悬浮动画效果 */
.hero-section:hover::after {
  opacity: 0.5;  /* 保持固定透明度 */
  transform: none;  /* 移除缩放效果 */
}

/* 调整渐变遮罩 */
.hero-section::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  mix-blend-mode: multiply;
}

/* 确保内容在最上层 */
.hero-title, .cta-buttons {
  position: relative;
  z-index: 2;
}

.hero-title {
  font-size: 64px;
  font-weight: 700;
  margin-bottom: 50px;
  color: #fff;
  animation: titleFadeIn 1.2s ease-out forwards;
  opacity: 0;
  transform: translateZ(50px);
  transition: transform 0.3s ease-out;
}

@keyframes titleFadeIn {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

/* 按钮容器样式 */
.cta-buttons {
  display: flex;
  gap: 20px;
  opacity: 0;  /* 初始透明 */
  animation: titleFadeIn 1.2s ease-out forwards;  /* 使用相同的动画 */
  animation-delay: 0.6s;  /* 在价格之后显示 */
}

/* 按钮样式 */
.learn-more-btn {
  font-size: 17px;
  padding: 12px 28px;
  border-radius: 980px;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.2);
  color: #fff;
  transition: all 0.3s ease;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  cursor: pointer;
  display: inline-block;
  transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
  position: relative;
  overflow: hidden;
}

.learn-more-btn::after {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    90deg,
    transparent,
    rgba(255, 255, 255, 0.2),
    transparent
  );
  transition: 0.5s;
}

.learn-more-btn:hover::after {
  left: 100%;
}

.learn-more-btn:hover {
  background: rgba(255, 255, 255, 0.2);
  border-color: rgba(255, 255, 255, 0.3);
  transform: translateY(-2px);
  text-decoration: none;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

.learn-more-btn:active {
  transform: translateY(0);
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
}

.buy-btn {
  font-size: 17px;
  padding: 8px 16px;
  border-radius: 980px;
  background: none;
  border: none;
  color: #2997ff;
}

.product-showcase {
  margin: 0;
  padding: 40px 0;
  position: relative;
  background: #000;
  width: 100%;
  min-height: 100%;
  overflow: hidden; /* 防止溢出 */
}

.image-grid {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  overflow-x: auto;
  gap: 40px;
  padding: 0 40px;
  width: 100%;
  scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scroll-behavior: smooth;
}

/* 隐藏 WebKit 滚动条 */
.image-grid::-webkit-scrollbar {
  display: none;
}

.image-container {
  flex: 0 0 auto;
  width: 800px;
  height: 1000px;
  position: relative;
  overflow: hidden;
  border-radius: 20px;
  background: #111;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  scroll-snap-align: start;
}

.image-container:hover {
  transform: translateY(-10px);
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.3);
}

.product-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
  margin: 0 auto;
  transition: transform 0.3s ease;
}

.image-container:hover .product-image {
  transform: scale(1.05);
}

.content-wrapper {
  font-family: -apple-system, BlinkMacSystemFont, "SF Pro Text", "Helvetica Neue", Arial, sans-serif;
}

.content-wrapper :deep(.content-paragraph) {
  font-size: 21px;
  line-height: 1.5;
  margin-bottom: 1.5em;
  color: #f5f5f7;
  opacity: 0;
  transform: translateY(20px);
  animation: fadeInUp 0.8s ease forwards;
  animation: textFadeIn 0.8s ease-out forwards;
  animation-delay: calc(var(--paragraph-index, 0) * 0.2s);
}

@keyframes textFadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.content-wrapper :deep(h2) {
  font-size: 48px;
  font-weight: 600;
  margin-bottom: 24px;
  background: linear-gradient(90deg, #fff, #f5f5f7);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  opacity: 0;
  transform: translateY(20px);
  animation: fadeInUp 0.6s ease forwards;
}

.content-wrapper :deep(strong) {
  color: #2997ff;
  font-weight: 600;
}

.content-wrapper :deep(ul) {
  list-style: none;
  padding: 0;
  margin: 20px 0;
}

.content-wrapper :deep(li) {
  font-size: 19px;
  line-height: 1.4;
  margin-bottom: 12px;
  padding-left: 24px;
  position: relative;
}

.content-wrapper :deep(li)::before {
  content: "•";
  color: #2997ff;
  position: absolute;
  left: 0;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* 视频区域样式优化 */
.video-section {
  margin: 100px 0;  /* 上下增加间距 */
  max-width: none;
  padding: 0;
  position: relative;
  width: 100%;
  height: 93vh;
  background: #000;
  overflow: hidden;  /* 防止滚动溢出 */
}

/* 视频容器样式 */
.video-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 0;
  overflow: hidden;
  background: #000;
  box-shadow: none;
  pointer-events: none;  /* 防止iframe捕获滚动事件 */
}

/* iframe样式 */
.video-wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
  object-fit: cover;
  pointer-events: auto;  /* 允许视频交互 */
}

/* 移除悬浮效果 */
.video-wrapper:hover {
  transform: none;
  box-shadow: none;
}

.contact-section {
  margin-top: 60px;  /* 增加与上一个区块的间距 */
  text-align: center;
  padding: 60px 20px;
  background: linear-gradient(180deg, #111 0%, #000 100%);
  position: relative;
  overflow: hidden;
  border-radius: 40px;
}

.contact-section::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 1px;
  background: linear-gradient(90deg, transparent, rgba(255,255,255,0.2), transparent);
}

.contact-section h2 {
  font-size: 40px;
  margin-bottom: 16px;
}

.contact-section p {
  font-size: 21px;
  color: #86868b;
  margin-bottom: 30px;
}

.contact-btn {
  font-size: 17px;
  padding: 18px 31px;
  border-radius: 980px;
  background: linear-gradient(180deg, #fff 0%, #f5f5f7 100%);
  color: #000;
  border: none;
  transition: all 0.3s ease;
  box-shadow: 0 2px 8px rgba(0,0,0,0.2);
  transform-origin: center;
}

.contact-btn:hover {
  transform: scale(1.05);
  background: linear-gradient(180deg, #fff 0%, #e5e5e7 100%);
  box-shadow: 0 4px 12px rgba(0,0,0,0.3);
}

/* 响应式设计 */
@media (max-width: 768px) {
  .hero-section {
    height: 90vh;  /* 稍微减小高度 */
    margin-bottom: 60px;  /* 减小底部间距 */
    padding: 0 20px;  /* 添加水平内边距 */
  }

  .hero-title {
    font-size: 32px;  /* 减小标题字体 */
    padding: 0;
    margin-bottom: 30px;
  }

  /* 价格容器移动端样式 */
  .price-container {
    margin: 16px 0 24px;
  }

  .price-tag {
    font-size: 36px;
  }

  .currency {
    font-size: 24px;
  }

  .price-details {
    flex-direction: row;  /* 保持水平排列 */
    flex-wrap: wrap;  /* 允许换行 */
    justify-content: center;
    gap: 12px;
    font-size: 14px;
  }

  .original-price {
    font-size: 14px;
  }

  .discount-tag {
    font-size: 12px;
    padding: 3px 8px;
  }

  /* 按钮移动端样式 */
  .cta-buttons {
    flex-direction: column;  /* 垂直排列 */
    gap: 12px;
    width: 100%;  /* 占满宽度 */
    max-width: 280px;  /* 限制最大宽度 */
  }

  .learn-more-btn {
    width: 100%;  /* 按钮占满容器宽度 */
    font-size: 16px;
    padding: 12px 24px;
  }

  /* 背景图片移动端适配 */
  .hero-section::after {
    background-size: cover;  /* 改为 cover 以确保填满 */
    background-position: center;
    opacity: 0.4;  /* 稍微降低透明度 */
  }

  .image-grid {
    gap: 20px;
    padding: 0 20px;
  }

  .image-container {
    width: 300px;
    height: 450px;
  }

  .product-image {
    width: 100%;
    height: 100%;
  }
}

/* 小屏幕适配 */
@media (max-width: 375px) {
  .hero-title {
    font-size: 28px;
  }

  .price-tag {
    font-size: 32px;
  }

  .currency {
    font-size: 22px;
  }
}

/* 暗色模式适配 */
@media (prefers-color-scheme: dark) {
  .slide-content {
    background: rgba(0, 0, 0, 0.8);
  }
}

/* 产品详情板块样式 */
.product-details {
  margin: 60px 0;  /* 减小上下边距 */
  background: linear-gradient(180deg, #000 0%, #111 100%);
  position: relative;
  overflow: hidden;
}

/* 内容容器样式 */
.content-container {
  max-width: 100%;
  margin: 0 auto;
  background: rgba(25, 25, 25, 0.8);
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
  border-radius: 40px;
  padding: 80px;
  box-shadow: 0 30px 60px rgba(0,0,0,0.4);
  border: 1px solid rgba(255, 255, 255, 0.08);
}

/* 内容样式 */
.content-wrapper :deep(.content-paragraph) {
  font-size: 21px;
  line-height: 1.6;
  margin-bottom: 2em;
  color: #f5f5f7;
  letter-spacing: 0.012em;
  font-weight: 400;
}

.content-wrapper :deep(h2) {
  font-size: 56px;
  font-weight: 600;
  margin-bottom: 40px;
  color: #fff;
  letter-spacing: -0.003em;
  line-height: 1.1;
}

.content-wrapper :deep(strong) {
  color: #2997ff;
  font-weight: 600;
}

/* 响应式调整 */
@media (max-width: 768px) {
  .product-details {
    margin: 40px 0;
    padding: 60px 15px;
  }

  .content-container {
    padding: 40px 24px;
    border-radius: 30px;
  }

  .content-wrapper :deep(.content-paragraph) {
    font-size: 17px;
    line-height: 1.5;
    margin-bottom: 1.5em;
  }

  .content-wrapper :deep(h2) {
    font-size: 40px;
    margin-bottom: 30px;
  }
}

/* 添加渐入动画 */
.content-container {
  opacity: 0;
  transform: translateY(30px);
  animation: fadeInContent 1s ease-out forwards;
  animation-delay: 0.2s;
}

@keyframes fadeInContent {
  from {
    opacity: 0;
    transform: translateY(30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* 添加动画效果 */
.hero-section:hover::after {
  opacity: 0.5;  /* 保持固定透明度 */
  transform: none;  /* 移除缩放效果 */
}

/* 添加渐入动画 */
.product-showcase.appear {
  animation: fadeIn 1s ease-out forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* 价格容器样式 */
.price-container {
  position: relative;
  z-index: 2;
  margin: 20px 0 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  opacity: 0;  /* 初始透明 */
  animation: titleFadeIn 1.2s ease-out forwards;  /* 使用相同的动画 */
  animation-delay: 0.3s;  /* 在标题之后显示 */
}

/* 价格标签样式 */
.price-tag {
  display: flex;
  align-items: baseline;
  font-size: 48px;
  font-weight: 600;
  color: #fff;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.currency {
  font-size: 32px;
  margin-right: 4px;
  font-weight: 500;
}

.amount {
  letter-spacing: -0.02em;
}

/* 价格详情样式 */
.price-details {
  display: flex;
  align-items: center;
  gap: 16px;
  font-size: 17px;
  color: #86868b;
}

.original-price {
  text-decoration: line-through;
  opacity: 0.8;
  color: #fff; 
}

.discount-tag {
  background: #2997ff;
  color: #fff;
  padding: 4px 10px;
  border-radius: 20px;
  font-size: 14px;
  font-weight: 500;
}

/* 移动端适配 */
@media (max-width: 768px) {
  .price-container {
    margin: 16px 0 32px;
  }

  .price-tag {
    font-size: 36px;
  }

  .currency {
    font-size: 24px;
  }

  .price-details {
    font-size: 15px;
    flex-direction: column;
    gap: 8px;
  }

  .discount-tag {
    font-size: 13px;
    padding: 3px 8px;
  }
}

/* 添加滚动触发动画类 */
.scroll-trigger {
  opacity: 0;
  transform: translateY(30px);
  transition: all 0.8s cubic-bezier(0.4, 0, 0.2, 1);
}

.scroll-trigger.visible {
  opacity: 1;
  transform: translateY(0);
}

/* 预览对话框样式 */
:deep(.preview-dialog) {
  background: rgba(0, 0, 0, 0.9);
  border: none;
  border-radius: 0;
  margin: 0 !important;
  padding: 0;
  width: 100vw !important;
  height: 100vh !important;
  max-width: 100% !important;
  position: fixed !important;
  top: 0 !important;
  left: 0 !important;
  transform: none !important;
}

:deep(.preview-dialog .el-dialog__header),
:deep(.preview-dialog .el-dialog__body) {
  padding: 0;
  margin: 0;
}

.preview-image {
  width: 100%;
  height: 100vh;
  object-fit: contain;
  cursor: zoom-out;
  display: block;
  margin: 0 auto;
  position: relative;
  z-index: 2000;
}

/* 添加关闭按钮样式 */
.preview-close {
  position: fixed;
  top: 20px;
  right: 20px;
  width: 40px;
  height: 40px;
  background: rgba(255, 255, 255, 0.15);
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s ease;
  z-index: 2001;
}

.preview-close:hover {
  background: rgba(255, 255, 255, 0.25);
  transform: scale(1.1);
}

.preview-controls {
  position: fixed;
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-50%);
  display: flex;
  justify-content: center;
  gap: 20px;
  padding: 0 20px;
  pointer-events: none;
  z-index: 2001;
}

.preview-nav-btn {
  background: rgba(255, 255, 255, 0.15);
  border: 1px solid rgba(255, 255, 255, 0.3);
  color: #fff;
  width: 50px;
  height: 50px;
  pointer-events: auto;
  transition: all 0.3s ease;
  z-index: 2001;
  cursor: pointer;
  position: absolute;
}

.preview-nav-btn:first-child {
  left: 20px;
}

.preview-nav-btn:last-child {
  right: 20px;
}

@media (max-width: 768px) {
  .preview-controls {
    padding: 0 10px;
  }

  .preview-nav-btn {
    width: 40px;
    height: 40px;
  }

  .preview-nav-btn:first-child {
    left: 10px;
  }

  .preview-nav-btn:last-child {
    right: 10px;
  }

  .preview-close {
    top: 10px;
    right: 10px;
    width: 36px;
    height: 36px;
  }
}

.gallery-controls {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 30px;
}

.gallery-nav-btn {
  background: rgba(255, 255, 255, 0.15);
  border: 1px solid rgba(255, 255, 255, 0.3);
  color: #fff;
  width: 50px;
  height: 50px;
  transition: all 0.3s ease;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
}

.gallery-nav-btn:hover {
  background: rgba(255, 255, 255, 0.25);
  transform: scale(1.1);
}

.gallery-nav-btn i {
  font-size: 20px;
}

@media (max-width: 768px) {
  .gallery-controls {
    margin-top: 20px;
    gap: 15px;
  }

  .gallery-nav-btn {
    width: 40px;
    height: 40px;
  }

  .gallery-nav-btn i {
    font-size: 16px;
  }
}
</style>
