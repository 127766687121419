import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import axios from 'axios';

// 将 axios 绑定到 Vue 原型上，方便全局使用
Vue.prototype.$axios = axios;

// 配置 axios 默认的根路径
axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;

Vue.use(ElementUI);

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
