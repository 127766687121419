<template>
  <div>
    <div class="chat-container" v-if="visible">
      <p id="window-title">Online customer service</p>

      <div class="message-container" ref="messageContainer">
        <div v-for="(msg, index) in messages" :key="index" class="message">
          <div v-html="msg" @click="handleImageClick"></div>
        </div>
        <!-- 使用用户信息表单组件 -->
        <UserInfoForm v-if="userInfoSubmitted" @submit="handleUserInfoSubmit" />
      </div>

      <div class="message-controls">
        <label class="control-button">
          <input type="file" accept="image/jpeg, image/png, image/jpg"
            @change="handleImageUpload" style="display: none;">
          <i class="fas fa-image"></i>
        </label>
      </div>

      <form @submit.prevent="sendMessage">
        <div class="input-container">
          <textarea v-model="sendMsg" placeholder="Enter a message" @keydown="handleKeydown" />
          <button class="send-button" type="submit">Send</button>
        </div>
      </form>
    </div>

    <!-- 添加图片预览模态框 -->
    <div v-if="showImagePreview" class="image-preview-modal" @click="closeImagePreview">
      <img :src="previewImageUrl" alt="Preview" class="preview-image" />
    </div>

    <button class="toggle-button" @click="toggleChat">
      {{ visible ? 'Hide' : 'Customer Service' }}
      <span v-if="!visible && unreadCount > 0" class="unread-badge">
        {{ unreadCount }}
      </span>
    </button>
  </div>
</template>

<script>
import UserInfoForm from "@/views/UserInfoForm.vue"; // 引入用户信息表单组件
export default {
  components: {
    UserInfoForm,
  },
  data() {
    return {
      visible: true, // 默认显示聊天窗口
      websocket: null,
      sendMsg: '',
      messages: [],
      userEmail: '',
      userInfoSubmitted: true, // 用户信息是否已提交
      sid: '',
      showImagePreview: false,
      previewImageUrl: '',
      // 添加未读消息相关状态
      unreadCount: 0,
      windowFocused: true,
      originalTitle: document.title,
      titleInterval: null,
    };
  },
  created() {
    this.initWebSocket();
    this.startHeartbeat();
    // 添加窗口焦点监听
    document.addEventListener('visibilitychange', this.handleVisibilityChange);
    window.addEventListener('focus', () => {
      this.windowFocused = true;
      this.stopTitleFlash();
    });
    window.addEventListener('blur', () => {
      this.windowFocused = false;
    });
  },
  beforeDestroy() {
    // 清理事件监听
    document.removeEventListener('visibilitychange', this.handleVisibilityChange);
    window.removeEventListener('focus', this.handleFocus);
    window.removeEventListener('blur', this.handleBlur);
    this.stopTitleFlash();
  },
  methods: {
    handleKeydown(event) {
      if (event.key === 'Enter') {
        this.sendMessage(); // 按下回车键时发送消息
      }
    },
    startHeartbeat() {
      this.heartbeatInterval = setInterval(() => {
        if (this.websocket && this.websocket.readyState === WebSocket.OPEN) {
          this.websocket.send(JSON.stringify({ msg: "ping" })); // 发送 ping 消息
        }
      }, 30000); // 每 30 秒发送一次 ping 消息
    },

    stopHeartbeat() {
      clearInterval(this.heartbeatInterval);
    },
    // 处理用户信息提交
    handleUserInfoSubmit(userInfo) {
      this.setMessageInnerHTML(`The email submission is successful! email：${userInfo.email}`);
      this.userInfoSubmitted = false; // 标记用户信息已提交
      const msg = userInfo.email;
      const sendMsg = {
        "msg": msg,
        "isEmail": "1",
      };
      this.websocket.send(JSON.stringify(sendMsg));
    },
    initWebSocket() {
      if ('WebSocket' in window) {
        this.websocket = new WebSocket(process.env.VUE_APP_WEBSOCKET_URL + window.location.hostname);

        this.websocket.onerror = () => {
          console.log("Connect error!");
        };

        this.websocket.onopen = () => {
          //this.setMessageInnerHTML("Successfully connected with customer service! Please submit your email and we will contact you as soon as possible!");
        };

        this.websocket.onmessage = (event) => {
          const result = event.data;
          try {
            const ob = JSON.parse(result);
            const fromId = ob.fromId;
            const msg = ob.msg;
            const msgType = ob.msgType;
            if (ob.sid) {
              this.sid = ob.sid;
            }
            if (msg === 'pong' && fromId === 'service') {
              return;
            } else if (msg) {
              this.setMessageInnerHTML(msg, fromId, msgType);
            } else {
              this.getUserMsg(ob);
            }
          } catch (e) {
            this.setMessageInnerHTML(result);
          }
        };

        this.websocket.onclose = (e) => {
          console.log('websocket 断开: ' + e.code + ' ' + e.reason + ' ' + e.wasClean)
          console.log("Connect disConnection");
          this.stopHeartbeat(); // 停止心跳机制
        };

        window.onbeforeunload = () => {
          this.websocket.close();
        };
      } else {
        console.log("Don't support websocket!");
      }
    },
    getUserMsg(str) {
      // 检查顶层的 isEmail 字段
      if (str.isEmail === 1) {
        this.userInfoSubmitted = false; // 如果有邮箱记录，隐藏邮箱提交框
      }

      if (str && str.chat && str.chat.length > 0) {
        // 显示历史消息
        str.chat.forEach(item => {
          const fromId = item.fromId;
          let msg = item.sendMsg;
          const msgType = item.msgType;

          if (fromId === this.sid && msgType === '2') {
            msg = '<p style="text-align: right; color: green">me: ' + `<img src="${msg}" alt="User Image" style="max-width: 200px; max-height: 200px;"/>` + '</p>';
          }

          // 根据消息类型和发送者显示不同样式的消息
          this.setMessageInnerHTML(msg, fromId, msgType);
        });
      }
    },
    setMessageInnerHTML(innerHTML, fromId, msgType) {
      if (fromId && fromId === this.sid) {
        innerHTML = '<p style="text-align: right; color: green">me: ' + innerHTML + '</p>';
      } else if (fromId) {
        if (!this.visible) {
          this.unreadCount++;
        }

        if (!this.windowFocused) {
          this.startTitleFlash();
        }

        if (msgType === '2') {
          innerHTML = '<p style="text-align: left; color: blue">Customer Service: ' + `<img src="${innerHTML}" alt="User Image" style="max-width: 200px; max-height: 200px;"/>` + '</p>';
        } else if (msgType === '3') {
          const ob = JSON.parse(innerHTML);
          const fileType = ob.name.toLowerCase();
          let iconClass = 'fa-file-word';
          let iconColor = '#2B579A';  // Word 蓝色

          if (fileType.endsWith('xlsx') || fileType.endsWith('xls')) {
            iconClass = 'fa-file-excel';
            iconColor = '#217346';  // Excel 绿色
          } else if (fileType.endsWith('pdf')) {
            iconClass = 'fa-file-pdf';
            iconColor = '#FF0000';  // PDF 红色
          }
          innerHTML = '<p style="text-align: left; color: blue">Customer Service: ' + `<div class="file-message" style="display: flex; align-items: center; padding: 10px; background: #f5f5f5; border-radius: 5px; margin: 5px 0;">
                  <i class="fas ${iconClass}" style="font-size: 24px; margin-right: 10px; color: ${iconColor}"></i>
                  <div style="flex-grow: 1;">
                    <div style="font-weight: bold;">${ob.name}</div>
                    <div style="color: #666; font-size: 12px;">${ob.size}</div>
                  </div>
                  <a href="${ob.data}" download="${ob.name}" style="text-decoration: none; color: #1abc9c;">
                    <i class="fas fa-download"></i>
                  </a>
                </div>` + '</p>';
        } else {
          innerHTML = '<p style="text-align: left; color: blue">Customer Service: ' + innerHTML + '</p>';
        }
      }
      this.messages.push(innerHTML);
      // 如果聊天窗口是打开的，自动滚动到底部
      if (this.visible) {
        this.$nextTick(() => {
          this.scrollToBottom();
        });
      }
    },
    sendMessage() {
      const msg = this.sendMsg.trim();
      if (!msg) return;
      this.setMessageInnerHTML(msg, this.sid);
      const sendMsg = {
        "msg": msg,
      };
      this.websocket.send(JSON.stringify(sendMsg));
      this.sendMsg = '';
      this.scrollToBottom();
    },
    scrollToBottom() {
      const container = this.$refs.messageContainer;
      container.scrollTop = container.scrollHeight;
    },
    toggleChat() {
      this.visible = !this.visible;
      // 当打开聊天窗口时，标记消息为已读
      if (this.visible && this.unreadCount > 0) {
        this.markMessagesAsRead();
        this.stopTitleFlash();
      }
    },
    handleImageClick(event) {
      if (event.target.tagName === 'IMG') {
        this.previewImageUrl = event.target.src;
        this.showImagePreview = true;
      }
    },
    closeImagePreview() {
      this.showImagePreview = false;
      this.previewImageUrl = '';
    },
    handleVisibilityChange() {
      if (document.hidden) {
        this.windowFocused = false;
      } else {
        this.windowFocused = true;
        this.stopTitleFlash();
        if (this.unreadCount > 0) {
          this.markMessagesAsRead();
        }
      }
    },
    startTitleFlash() {
      if (this.titleInterval) return;

      let isOriginal = true;
      this.titleInterval = setInterval(() => {
        document.title = isOriginal ? '【New Message】' + this.originalTitle : this.originalTitle;
        isOriginal = !isOriginal;
      }, 1000);
    },
    stopTitleFlash() {
      if (this.titleInterval) {
        clearInterval(this.titleInterval);
        this.titleInterval = null;
        document.title = this.originalTitle;
      }
    },
    markMessagesAsRead() {
      this.unreadCount = 0;
    },
    handleImageUpload(event) {
      const file = event.target.files[0];
      if (!file) return;

      // 检查文件类型
      if (!file.type.startsWith('image/')) {
        alert('Please select an image file');
        return;
      }

      // 检查文件大小（限制为5MB）
      if (file.size > 5 * 1024 * 1024) {
        alert('Image size should not exceed 5MB');
        return;
      }

      const reader = new FileReader();
      reader.onload = (e) => {
        const imageUrl = e.target.result;
        const imageHtml = `<img src="${imageUrl}" alt="User Image"
          style="max-width: 200px; max-height: 200px; cursor: pointer;"
          onclick="document.dispatchEvent(new CustomEvent('preview-image', {detail: '${imageUrl}'}))">`;

        this.setMessageInnerHTML(imageHtml, this.sid, '2');
        const sendMsg = {
          "msg": imageUrl,
          "msgType": "2"
        };
        this.websocket.send(JSON.stringify(sendMsg));
      };
      reader.readAsDataURL(file);

      // 清空input以允许选择相同的文件
      event.target.value = '';
    },
  },
  updated() {
    this.scrollToBottom();
  }
};
</script>

<style scoped>
.chat-container {
  position: fixed;
  bottom: 80px;
  right: 20px;
  width: 90%;
  max-width: 400px;
  border: none;
  border-radius: 16px;
  background-color: rgba(255, 255, 255, 0.98);
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.1);
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, sans-serif;
  display: flex;
  flex-direction: column;
  z-index: 1000;
  backdrop-filter: blur(10px);
  transition: all 0.3s ease;
}

#window-title {
  font-weight: 600;
  text-align: center;
  background: linear-gradient(135deg, #3699ff 0%, #2c7be5 100%);
  color: white;
  padding: 15px 0;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  margin: 0;
  font-size: 16px;
  letter-spacing: 0.5px;
}

.message-container {
  flex-grow: 1;
  height: 400px;
  max-height: 360px;
  overflow-y: auto;
  margin: 10px;
  padding: 15px;
  border-radius: 12px;
  background: rgba(248, 249, 250, 0.8);
  scrollbar-width: thin;
  scrollbar-color: rgba(0, 0, 0, 0.2) transparent;
}

.message {
  padding: 12px 16px;
  border: none;
  border-radius: 12px;
  background-color: white;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  margin: 10px 0;
  transition: transform 0.2s ease;
}

.message:hover {
  transform: translateY(-2px);
}

.message-controls {
  padding: 12px;
  border-bottom: 1px solid #e2e8f0;
  display: flex;
  gap: 8px;
  background-color: #fff;
}

.control-button {
  width: 34px;
  height: 34px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #64748b;
  transition: all 0.15s ease;
  border: none;
  background: none;
  cursor: pointer;
}

.control-button:hover {
  background-color: #f1f5f9;
  color: #0ea5e9;
}

.control-button i {
  font-size: 16px;
}

.input-container {
  display: flex;
  width: 100%;
  gap: 12px;
}

textarea {
  flex-grow: 1;
  margin-right: 12px;
  padding: 12px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  resize: none;
  height: 88px;
  font-size: 16px;
  background: white;
  transition: all 0.3s ease;
}

textarea:focus {
  outline: none;
  border-color: #3699ff;
  box-shadow: 0 0 0 3px rgba(54, 153, 255, 0.1);
}

.send-button {
  height: 115px;
  width: 80px;
  background: linear-gradient(135deg, #3699ff 0%, #2c7be5 100%);
  color: white;
  border: none;
  border-radius: 12px;
  font-weight: 600;
  font-size: 15px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.send-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(54, 153, 255, 0.2);
}

.toggle-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  padding: 12px 20px;
  background: linear-gradient(135deg, #3699ff 0%, #2c7be5 100%);
  color: white;
  border: none;
  border-radius: 12px;
  font-weight: 600;
  cursor: pointer;
  z-index: 1001;
  width: 100px;
  height: 50px;
  transition: all 0.3s ease;
  box-shadow: 0 4px 15px rgba(54, 153, 255, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
}

.toggle-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 8px 20px rgba(54, 153, 255, 0.3);
}

.image-preview-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.85);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000;
  cursor: pointer;
  backdrop-filter: blur(5px);
}

.preview-image {
  max-width: 90%;
  max-height: 90vh;
  object-fit: contain;
  border-radius: 8px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);
}

.message-container::-webkit-scrollbar {
  width: 6px;
}

.message-container::-webkit-scrollbar-track {
  background: transparent;
}

.message-container::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 3px;
}

.unread-badge {
  position: absolute;
  top: -8px;
  right: -8px;
  background-color: #ff4444;
  color: white;
  border-radius: 50%;
  min-width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: bold;
  padding: 0 4px;
  box-shadow: 0 2px 5px rgba(0,0,0,0.2);
  z-index: 1002;
}
</style>
