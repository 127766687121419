<template>
  <form class="user-info-form" @submit.prevent="submitUserInfo">
    <input type="email" v-model="userEmail" placeholder="Enter your email address" required />
    <button type="submit">submit</button>
  </form>
</template>

<script>
export default {
  data() {
    return {
      userEmail: ''
    };
  },
  methods: {
    submitUserInfo() {
      this.$emit("submit", { email: this.userEmail });
      this.userEmail = '';
    }
  }
}
</script>

<style scoped>
.user-info-form {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 10px;
  padding: 15px;
  border-radius: 8px;
  background-color: #f9f9f9;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.user-info-form input[type="email"] {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  transition: border-color 0.3s;
  width: 100%;
}

.user-info-form input[type="email"]:focus {
  border-color: #007bff;
  outline: none;
}

.user-info-form button {
  padding: 10px;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
  width: 107%;
}

.user-info-form button:hover {
  background-color: #218838;
}
</style>
